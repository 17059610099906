import http from '../index.js'

/**
 * 按类别统计销售
 * @returns
 */
export const salesKindApi = params => {
  const url = `/web/fullScreen/statistics/sales/kind`
  return http.post(url, params)
}

/**
 * 按年月统计销售
 * @returns
 */
export const salesMonthlyApi = params => {
  const url = `/web/fullScreen/statistics/sales/monthly`
  return http.post(url, params)
}

/**
 * 按原因统计损耗
 * @returns
 */
export const lossReasonApi = params => {
  const url = `/web/fullScreen/statistics/loss/reason`
  return http.post(url, params)
}

/**
 * 按月统计损耗
 * @returns
 */
export const lossMonthlyApi = params => {
  const url = `/web/fullScreen/statistics/loss/monthly`
  return http.post(url, params)
}

// 获取布局分页信息
export const bizbanner = baseId => {
console.log(baseId,'baseId')
  const url = baseId ? `/web/fullScreen/biz/banner?baseId=${baseId}`:`/web/fullScreen/biz/banner`
  return http.get(url)
}
