<template>
  <div class="">
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
import { months, monthlySalesDistributionSeries } from "../mockData";
import EChart from "../../../common/echart/index.vue";
import { salesMonthlyApi } from '@/api/modules/management'

export default {
  components: { EChart },
  data() {
    return {
      options: {
        title: {
          text: "月度销售额分布图",
          left: "1%",
          top: "3%",
        },
        legend: {
          data: ["罗非", "鲫鱼苗", "鳜鱼"],
          top: "15%",
          right: "15%",
        },
        grid: {
          top: "22%",
          bottom: "15%",
          left: "12%",
          right: "8%",
        },
        tooltip: {},
        xAxis: {
          data: months,
        },
        yAxis: {},
        series: monthlySalesDistributionSeries,
      },
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(){
      // 按年月统计销售
          let {data ={},code} =  await  salesMonthlyApi({})||[] 
          let monthlySalesDistributionSeries1=[]  
          //  if(!data.items||data.items?.length=== 0){
                //  data.items = [
                //                 {
                //                   "title": "2025-01",
                //                   "code": "2025-01",
                //                   "quantity": 305.000,
                //                   "items": [
                //                       {
                //                           "title": "草鱼",
                //                           "code": "30101",
                //                           "quantity": 301.000
                //                       },
                //                       {
                //                           "title": "青鱼",
                //                           "code": "30102",
                //                           "quantity": 4.000
                //                       }
                //                   ]
                //               }
                //           ]
                //           this.options.xAxis.data=[]
          //  }
           console.log(data.items,'data.itemssalesMonthlyApi')
           this.options.xAxis.data=[]
             this.options.legend.data=[]
           if(data.items?.length>0){
           data.items.forEach((item1,index) => {
                  //  item1可以取月份
             this.options.xAxis.data.push(item1.code)
             let  item2=item1.items; //最后一级
             if(index===0){
              monthlySalesDistributionSeries1 =item2
              monthlySalesDistributionSeries1.forEach(Series2=>{
                  this.options.legend.data.push(Series2.title)
                       Series2.data =[]
                        Series2.name =Series2.title
                        Series2.type ='bar'
                        Series2.stack ='total'
                        Series2.label ={
                                show: true
                              }
                        Series2.emphasis ={
                            focus: 'series'
                          }
                      
              })
             }
             
             item2.forEach(item3=>{
               monthlySalesDistributionSeries1.forEach(Series1=>{
                if(Series1.code ===item3.code){
                    Series1.data.push(item3.quantity)
                }
                 
               })
             })
           })
           this.options.series = monthlySalesDistributionSeries1
           }
             console.log(data,code,this.options,'resitemssalesMonthlyApi')
     }
  },
};
</script>

<style lang="scss" scoped>
#box {
}
</style>
