<template>
  <div class="">
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
import { lossAnalysisPieData } from "../mockData";
import EChart from "../../../common/echart/index.vue";
import { lossReasonApi} from '@/api/modules/management'
export default {
  components: {
    EChart,
  },
  data() {
    return {
      options: {
        title: {
          text: "损失分析",
          left: "1%",
          top: "3%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "3%",
          top: "middle",
          data: lossAnalysisPieData.categories,
        },
        grid: {
          left: "5%",
          right: "25%",
        },
        series: [
          {
            name: "损失值",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["60%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 24,
                fontWeight: "bold",
              },
            },
            data: lossAnalysisPieData.categories.map((category, index) => {
              return {
                name: category,
                value: lossAnalysisPieData.loss[index],
              };
            }),
          },
        ],
      },
    };
  },
  methods: {
    async getData(){
      let {data ={},code} =  await  lossReasonApi({})||[] 
// categories: ['罗非', '鲫鱼苗', '鳜鱼'],
//   loss: [3550, 0, 420]
  const {items} =data
     if(items?.length> 0){
       this.options.legend.data =[]
      this.options.series[0].data =[]
        items.forEach((item1) => {
         this.options.legend.data.push(item1.title)
         this.options.series[0].data.push({
           name: item1.title,
           value:item1.quantity,
         })
      })
     }
      console.log(data,code,this.options,'res-lossReasonApi')
    }
  },
   mounted() {
     this.getData()
   }
};
</script>
