<template>
  <div class="">
    <dv-border-box-12>
      <e-chart :options="options" id="box" height="350px" width="100%"></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
  import { months, categories } from '../mockData'
  import EChart from '../../../common/echart/index.vue'
  import { lossMonthlyApi} from '@/api/modules/management'
  export default {
    components: {
      EChart
    },
    data() {
      return {
        options: {
          title: {
            text: '渔业分类损失趋势',
            left: '1%',
            top: '3%'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: categories,
            orient: 'horizontal',
            left: '35%',
            top: '15%',
            show:true,
          },
          grid: {
            
            left: '5%',
            right: '5%',
            bottom: '9%',
            top: '25%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: months
          },
          yAxis: {
            type: 'value',
            name: '损失量（斤）'
          },
          series: [
            {
              name: '罗非',
              type: 'line',
              stack: 'Total',
              data: [150, 200, 100, 150, 300, 600, 600, 650, 500, 300, 200, 150],
              smooth: false // 禁用曲线效果
            },
            {
              name: '鲫鱼苗',
              type: 'line',
              stack: 'Total',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              smooth: false // 禁用曲线效果
            },
            {
              name: '鳜鱼',
              type: 'line',
              stack: 'Total',
              data: [0, 0, 0, 20, 40, 60, 100, 100, 50, 50, 20, 30],
              smooth: false // 禁用曲线效果
            }
          ]
        }
      }
    },
    methods: {
    async getData(){
      let {data ={},code} =  await  lossMonthlyApi({})||[] 
      let monthlySalesDistributionSeries1=[] 
      this.options.xAxis.data=[]
      this.options.legend.data=[]
// categories = ['罗非', '鲫鱼苗', '鳜鱼']
     if(data.items?.length> 0){
           data.items.forEach((item1,index) => {
                  //  item1可以取月份
             this.options.xAxis.data.push(item1.code)
             let  item2=item1.items; //最后一级
             if(index===0){
              monthlySalesDistributionSeries1 =item2
              monthlySalesDistributionSeries1.forEach(Series2=>{
                         this.options.legend.data.push(Series2.title)
                       Series2.data =[]
                        Series2.name =Series2.title
                        Series2.type ='line'
                        Series2.stack ='Total'
                        Series2.smooth =false
                  
                      
              })
             }
             
             item2.forEach(item3=>{
               monthlySalesDistributionSeries1.forEach(Series1=>{
                if(Series1.code ===item3.code){
                    Series1.data.push(item3.quantity)
                }
                 
               })
             })
           })
           this.options.series = monthlySalesDistributionSeries1
            }
      console.log(data,code,this.options,'res-lossMonthlyApi')

    }
  },
   mounted() {
     this.getData()
   }
  }
</script>
